export default {
    computed: {
        showFullEditLink() {
            switch(this._data._class) {
                case 'Mail':
                case 'MailTemplateBlock':
                case 'BaseCourseAction':
                case 'Mailtemplate':
                case 'Holiday':
                case 'Renter':
                case 'Organisation':
                case 'Client':
                    return false;
                    break;
                default:
                    return true;
                    break;
            }
        },
    },
    methods: {
        
        getEditSubTitle() {
            
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'Contactperson':         entityName = 'Contactpersoon'; break;
                case 'Project':               entityName = 'Project'; break;
                case 'Customer':              entityName = 'Klantgegevens'; break;
                case 'Invoice':               entityName = 'Factuur'; break;
                case 'Organisation':          entityName = 'Organisatie'; break;
                
            }
            return entityName;
            
        },
        getEditTitle() {
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'Client':              entityName = ''; break;
                case 'Contactperson':       entityName = 'Contactpersoon'; break;
                case 'Project':             entityName = 'Project'; break;
                case 'Customer':            entityName = 'Klant'; break;
                case 'Organisation':        entityName = 'Organisatie'; break;
                
            }
            return entityName;
            
        },
        getEditName(data) {
            if (!data.id) return '';
            var html = [];

            
            switch(data._class) {
                case 'BaseCourse':
                case 'Course':
                case 'CourseLocation':
                case 'Customer':    
                    html.push(data.name+' - ID: '+data.id);
                    break;
                case 'Participant':    
                    html.push(data.fullname+'  ('+data.Customer.name+')');
                    break;
                case 'User':    
                    html.push(data.fullname+' - ID: '+data.id);
                    break;
                case 'MailTemplate':    
                    html.push((data.BaseCourse.acronym?data.BaseCourse.acronym:'Default')+(data.type?' - '+data.type+' template':''));
                    break;
                case 'Enrollment':    
                    html.push(data.Participant.fullname+'  ('+data.Customer.name+')');
                    break;
                case 'BaseCourseAction':    
                    html.push(data.BaseCourse.name);
                    break;
            }
            return html.join('<br />');
        },
    }
}

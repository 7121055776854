<template>

    <div class="loader" :class="{active:loaderState}">
      <i class="fa-solid fa-spinner-third fa-spin"></i>
    </div>
  
    <dialog class="modal" id="dialog">
      <form @submit.prevent="()=>{}">
          <h2 v-if="dialog && dialog.title" class="modalTitle">{{dialog.title}}</h2>
          <p v-if="dialog && dialog.text" v-html="dialog.text"></p>
  
          <!-- <FormKit v-if="dialog.fields && dialog.fields.length" type="form" v-model="dialogForm" :actions="false"> -->
          <FormKit v-if="dialog.fields && dialog.fields.length" type="form" v-model="dialogForm" :actions="false" @submit="dialog.buttons[dialog.buttons.length-1].action(this.dialogForm)">
              <FormKitSchema :schema="dialog.fields" />
          </FormKit>
  
          <div class="modalButtons" v-if="dialog">
              
            <template v-for="button in dialog.buttons">
                <button v-if="!button.action" class="modalButton modalButtonLine" @click="this.closeDialog">{{ button.label }}</button>
                <button v-else class="modalButton" :class="button.class" @click="()=>{button.action(this.dialogForm)}">{{ button.label }}</button>
            </template>
              <!-- <button class="modalButton modalButtonLine">Yup</button>
              <button class="modalButton modalButtonContrast">100%</button>
              <button class="modalButton">Jazeker</button> -->
          </div>
      </form>
    </dialog>
    
    <div class="alerts">
          
  
          <div v-for="alert in alerts" class="alert" :class="{debug:debugMode, alertSuccess:alert.type=='success', alertError:alert.type=='error', active:alert.active}">
              <div class="alertInfo">
                  <p class="alertTitle">{{alert.title}}</p>
                  <p class="alertMessage" v-html="$filters.nl2br(alert.message)"></p>
              </div>
              <a v-if="alert.title && alert.type=='error'" @click="hideAlert(alert.id)" class="alertClose cursor"><i class="fa-solid fa-xmark"></i></a>
          </div>
          
          
      </div>

      <!-- {{ isLoggedIn }}
      {{ loginValues }} -->
  
    <template v-if="!isLoggedIn">
      <div v-if="loginValues.loginType=='login'" class="loginScreen">
        <figure class="loginLogo">
          <img :src="logoDefault" :alt="companyName+' logo'" />
        </figure>
        <div class="loginPanel" v-if="$route.path=='/login'">
          <h1>Inloggen</h1>
          <input type="email" v-model="loginValues.email" placeholder="E-mailadres" @keyup.enter.native="login"/>
          <input type="password" v-model="loginValues.password" placeholder="Wachtwoord" @keyup.enter.native="login"/>
          <input type="submit" @click="login" value="Inloggen" class="btn"/>
          
          <a class="cursor" @click="loginValues.loginType='forgotpassword'" style="float:left">Ik heb nog geen account</a>
          <a class="cursor" @click="loginValues.loginType='forgotpassword'">Wachtwoord vergeten?</a>
        </div>
        <!-- <div class="loginPanel setup2fa" v-if="$route.path=='/setup2fa'">
          <h1>2FA instellen</h1>
          <div style="float:left;">
            <span>Scan de QR code met de Google Authenticator app om 2-factor-authenticatie in te stellen.</span>
            <input type="email" v-model="loginValues.setup2facode" placeholder="Code" @keyup.enter.native="setup2fa"/>
            <input type="submit" @click="setup2fa" value="Instellen" class="btn"/>
  
          </div>
          <img :src="'data:image/png;base64, '+$store.getters.get_login.qrCode"/>
  
        </div>
        <div class="loginPanel setup2fa" v-if="$route.path=='/2fa'">
          <h1>2FA</h1>
          <div style="float:left;">
            <span>Voer de code in uit de Google Authenticator app.</span>
            <input type="email" v-model="loginValues.login2facode" placeholder="Code" @keyup.enter.native="verify2fa"/>
            <input type="submit" @click="verify2fa" value="Instellen" class="btn"/>
  
          </div>
          <img src="/img/logo-GoogleAuthenticator.svg"/>
  
        </div> -->
      </div> 
      
      <div v-if="loginValues.loginType=='forgotpassword'" class="loginScreen">
        <figure class="loginLogo">
          <img :src="logoDefault" :alt="companyName+' logo'" />
        </figure>
        <div class="loginPanel">
          <h1>Nieuw wachtwoord aanvragen</h1>
          
            <input type="email" v-model="loginValues.email" placeholder="E-mailadres"/>
            <input type="submit" @click="submitForgotPassword" value="Versturen" class="btn"/>
          
            <a class="cursor" @click="loginValues.forgotPassword=false">Terug naar inloggen</a>
        </div>
      </div>    
      
      <div v-if="loginValues.loginType=='newpassword'" class="loginScreen">
        <figure class="loginLogo">
          <img :src="logoDefault" :alt="companyName+' logo'" />
        </figure>
        <div class="loginPanel">
          <h1>Nieuw wachtwoord instellen</h1>
          
            <input type="password" v-model="loginValues.newpassword" placeholder="Nieuw wachtwoord"/>
            <input type="password" v-model="loginValues.newpassword_confirm" placeholder="Nieuw wachtwoord bevestigen"/>
            <input type="submit" @click="submitNewPassword" value="Versturen" class="btn"/>
          
            <a class="cursor" @click="loginValues.forgotPassword=false">Terug naar inloggen</a>
        </div>
      </div>    
    
    </template>
  
    <template v-else>
          
      
  
  
        <!-------------------->
        <!-- CONTENT RECHTS -->
        <!-------------------->
        <section class="mainWrap" :class="mainWrapClass">
  
          <router-view
            @show-action-bar="showActionBar"
            @close-action-bar="closeSideBar"
            @close-side-bar="closeSideBar"
            @closeSideBar="closeSideBar"
            @closeActionBar="closeSideBar"
            @showAlert="showAlert"
            :key="$route.fullPath"
            />
  
        </section>
  
        <!-- <section :class="sidebarClass">
            <component
              v-if="sideBar.component"
              :is="sideBar.component"
              :_data="sideBar.data"
              
              @showActionBar="showActionBar"
              @closeSideBar="closeSideBar"
              @closeActionBar="closeSideBar"
              @close-side-bar="closeSideBar"
              @close-action-bar="closeSideBar"
              @show-alert="showAlert"
            ></component>
        </section> -->
  
    </template>
  
  </template>
  
  <script>
  import { classExpression } from '@babel/types';
  import { useRouter, useRoute } from 'vue-router'
  
  export default {
    emits:['showActionBar','closeSideBar','showAlert'],
    data() {
      return {
        baseColor: "blue",
        baseColor1: '#ff6600',
        debugMode:false,
        alerts: [],
        dialog: false,
        leftMenu:{
          open:1
        },
        loginValues: {
          loginType:'login',
          forgotPassword:false,
          email:'',
          password:'',
          newpassword:'',
          newpassword_confirm:'',
          login2facode:'',
          setup2facode:''
        },
        sideBar: {
          size:'small',
          component:false,
          data:false
        },
        dialogForm:{}
        //sideBarComponent: false,
        //sideBarComponentData: false
      }
    },
    methods: {
  
    //   async verify2fa() {
    //       const res = await this.callApi('post', '/login/verify2fa', {
    //           login2facode:this.loginValues.login2facode
    //       });
    //       if (res.data.result) {
    //         this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE);
    //         this.clearAlerts();
    //         this.showAlert('success', '2FA', "2FA succesvol voltooid!")
    //         this.reloadStores()
    //       }
    //       else {
    //         this.showAlert('error', 'Fout', "2FA code onjuist")
    //       }
    //   },
    //   async setup2fa() {
    //       const res = await this.callApi('post', '/login/setup2fa', {
    //           setup2facode:this.loginValues.setup2facode
    //       });
    //       if (res.data.result) {
    //         this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE);
    //         this.clearAlerts();
    //         this.showAlert('success', '2FA', "2FA succesvol ingesteld!")
    //       }
    //       else {
    //         this.showAlert('error', 'Fout', "2FA code onjuist", true)
    //       }
    //   },
  
      async login() {
          const res = await this.callApi('post', '/frontend/login', {
              email:this.loginValues.email,
              password:this.loginValues.password
          });
  
          if (res.data.result) {
            
            this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE);
            this.clearAlerts();
            this.showAlert('success', 'Login', "Succesvol ingelogd!")
            this.$router.push('/opdracht');
            //this.reloadStores()

            this.$store.dispatch('set_lastlogin', Math.floor(Date.now() / 1000))

            this.$store.dispatch('set_login', res.data.result)
            
          }
          else {
              //alert("Wrong email / password")
              //this.showAlert('error', 'Fout', "Login onjuist", true)
          }
      },
      async submitForgotPassword() {
          const res = await this.callApi('post', '/frontend/forgotPassword', {
              email:this.loginValues.email
          });

          if (res.data.result) {

            this.showAlert('success', 'Nieuw wachtwoord', "Nieuw wachtwoord aangevraagd, controleer uw email");
          }
          else {
              //alert("Wrong email / password")
              this.showAlert('error', 'Fout', "Account niet gevonden", true)
          }
          //this.loginValues.forgotPassword=false;
          this.loginValues.loginType='login';
      },
      async submitNewPassword() {
        console.log(this.loginValues.newpassword, this.loginValues.newpassword.length)
            if (!this.loginValues.newpassword) {
                this.showTemporaryErrorMessage('Fout', "Vul een nieuw wachtwoord in");
                return;
            }
            if (this.loginValues.newpassword.length < 6) {
                this.showTemporaryErrorMessage('Fout', "Wachtwoord moet minimaal 6 tekens bevatten");
                return;
            }
            if (this.loginValues.newpassword != this.loginValues.newpassword_confirm) {
                this.showTemporaryErrorMessage('Fout', "Wachtwoorden komen niet overeen");
                return;
            }
            const res = await this.callApi('post', '/frontend/setNewPassword', {
                    newpassword:this.loginValues.newpassword,
                    password_confirm:this.loginValues.newpassword_confirm,
                    id:this.$route.params.id,
                    hash:this.$route.params.validationToken,
            });
            
            this.loginValues.newpassword = '';
            this.loginValues.newpassword_confirm = '';
            if (!res.data.error) {
                this.showAlert('success', 'Nieuw wachtwoord', "Nieuw wachtwoord opgeslagen, u kunt nu inloggen");
            }
            this.loginValues.loginType='login';
            this.$router.push('/login');
      },
      clearAlerts() {
        this.alerts = [];
      },
      hideAlert(id) {
        //console.log('hide', id)
        this.alerts.forEach(alert=>{
          if (alert.id==id) {
            alert.active=false;
            setTimeout(() => {
              this.removeAlert(id)
            }, 2000);
          }
        })
      },
      removeAlert(id) {
        this.alerts = this.alerts.filter(function( alert ) {
            return alert.id !== id;
        }); 
      },
      showAlert(type, title, message, autoclose) {
        console.log('showAlert', type, title, message, autoclose)
        var id = Math.floor(Math.random() * 1000000000);
        this.alerts.push({
            'id':id,
            'type':type,
            'title':title,
            'active':true,
            'message':message
        })
  
        if (type=='success' || autoclose) {
          setTimeout(() => {
            this.hideAlert(id)
          }, 2000);
        }
      },
      async checkLogin() {
        const res = await this.callApi('get', '/frontend/check');
        console.log('checkLogin', res)
        if (res.data.debugMode) this.debugMode = 1;
        if (!res.data.result) {
          console.log("logged out", this.$router)
          this.$router.push('/login');
          this.hideLoader();
          return false;
          //document.location.href = '/';
        }
        else {
          this.$store.dispatch('set_login', res.data.result)
          return res.data.result;
          //this.reloadStores()
        }
      },
      async logout() {
        console.log('logout')
        var res = await this.callApi('post', '/login/logout');
        this.$router.push('/login');
      },
      async reloadStores() {
          //console.log('reloadStores')
          const res = await this.callApi('get', '/base/getInitStores');
          if (res.data.result && res.data.result.stores) {
            for (const key in res.data.result.stores) {
              //console.log(key, res.data.result.stores[key])
              this.$store.dispatch('set_'+key, res.data.result.stores[key])
            }
          }
          //this.$store.dispatch('set_login', res.data.login)
      },
  
      closeSideBar() {
        //console.log('closeSideBar')
        this.sideBar.component=false;
        this.sideBar.data = false;
      },
      showActionBar(component, data, size) {
        //console.log("showActionBar", data)
        if (this.sideBar.component) {
          this.closeSideBar()
          var that = this;
          setTimeout(function(){
            that.showActionBar(component, data, size)
          }, 200)
          return;
        }
        this.sideBar.size = size;
        this.sideBar.component = component;
        this.sideBar.data = data;
      },
      isActiveMainMenu(route) {
        if (this.$route.path=='/') return false;
  
        var mainSection = this.$route.path.split('/')[1];
        if (route.path=='/'+mainSection) {
          //console.log(route.path, mainSection);
          return true;
        }
        return false;
      }
    },
    watch: {
      '$route' (to, from) {
          //console.log(to,from)
          if (to.path=='/') {
            this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE)
            return
          }
          //to.loaded();
          this.closeSideBar()
          //this.updateAlertCounts()
      }
    },
    computed: {
  
      cssVars() {
        return {
          'baseColor1': '#310E4E'
        }
      },
  
      logoDefault() { return process.env.VUE_APP_LOGO_DEFAULT; },
      logoFull() { return process.env.VUE_APP_LOGO_FULL; },
      logoCollapsed() { return process.env.VUE_APP_LOGO_COLLAPSED; },
      companyName() { return process.env.VUE_APP_COMPANY_NAME; },
  
      loaderState() {
        //console.log('loaderState', this.$store.getters.getLoaderState)
        return this.$store.getters.get_loaderstate?true:false
      },
      mainWrapClass() {
        var c = [];
        var routes = this.$router.options.routes.filter(route=>{return route.name==this.$route.name});
        if (!this.leftMenu.open) c.push('collapsed');
        if (routes.length==1 && routes[0].className) c.push(routes[0].className);
        return c.join(' ');
      },
      sidebarClass() {
        if (this.sideBar.component) {
          var classes = ['sideBarWrap'];
          if (this.sideBar.size!='large') {
            classes.push('actionsSideBar');
          }
          classes.push('active');
          return classes.join(' ');
        }
        return 'sideBarWrap';
      },
      sidebarSize() {
        if (this.sideBar.component) {
            if (this.sideBar.size=='large') {
              return '600px';
            }
            else {
              return '400px';
  
            }
        }
        return '0px';
      },
      isLoggedIn() {
        let login = this.$store.getters.get_login
        if (!login || !login.id) return false;
        if (login.loginstep) return false;
        return this.$store.getters.get_login?true:false
      },
      getMainSectionTitle(section) {
        //console.log(this.mainMenuItems())
      },
      mainMenuItems() {
  
        var login = this.$store.getters.get_login;
        var allowedPages = [];
        if (login && login.pagePermissions) allowedPages = login.pagePermissions;
        
        return this.$router.options.routes.filter(route=>{
          if (!route.sectionTitle) return false;
          if (!route.icon) return false;
          if (login && login.Role && login.Role.superuser) return true;
          if (!allowedPages.includes(route.name)) return false;
          return (route.sectionTitle && route.path.match(/\//g) || []).length==1
        });
        
      },
      
      
    },
    async beforeMount() {
      console.log('App beforeMount', this.$route)
      
    },
    async mounted() {
        const router = useRouter();

        await router.isReady() 
        console.log('App mounted', this.$route.name, this.$route);
        if (this.$route.name=='new_password') {
            this.loginValues.loginType='newpassword';
            console.log('new_password'); 
            
        }
        else if (!this.isLoggedIn && this.$route.path!='/login') {
            let checkLoginResult = await this.checkLogin()
            console.log('checkLoginResult',checkLoginResult)
            if (!checkLoginResult) {
                console.log('forward to login')
                this.$router.push('/login');
                
            }
            else {
                console.log('forward to workshop')
                this.$router.push('/opdracht');

            }
        }
        //this.checkLogin()
    
        this.emitter.on('closeSideBar', e => {
            //console.log("closeSideBar by mitt", e)
            this.sideBar.component=false;
            this.sideBar.data = false;
        })
        this.emitter.on('showAlert', e => {
            console.log("showAlert by mitt", e)
    
            this.showAlert(e.type, e.title, e.message, e.autoclose)
        })
        this.emitter.on('showDialog', e => {
            this.dialog = {};
            this.dialog.title = e.title;
            this.dialog.text = e.text;
            this.dialog.fields = e.fields;
            this.dialog.buttons = e.buttons;

            this.dialogForm = {};
            document.getElementById('dialog').showModal();
        })
        this.emitter.on('hideDialog', e => {
            document.getElementById('dialog').close();
            //dialog.close();
            this.dialog = false;
        })
    
        //this.reloadStores()
        
        }
  }
  </script>
  
  <style>
  .alerts .alert.debug {
    width:120rem;
  }
  
  /* .sideBarWrap {
    transition: right 0.3s;
  } */
  
  
  .marked {
    background-color: yellow;
        background-color: yellow;
      border: 1px solid #310e4e;
      border-radius: 1rem;
      padding: 0.2rem 0.7rem 0.1rem 0.7rem;
  }
  
  .loginPanel.setup2fa img {
    width:200px;
    float:right;
  }
  .loginPanel.setup2fa div {
    float:left;
    width:60%;
    text-align: left;
  }
  .loginPanel.setup2fa div span {
    text-align: left;
    float:left;
    clear: both;
    margin-bottom: 2em;
    font-size: 1.4rem;
  }
  
  /* .mainWrap {
    right:14rem !important;
  } */
  /* .overviewTitleWrap, .tableTitleWrap, .tableEntryWrap, .tableEntryXLWrap {
      min-width: 120rem !important;
  } */
  
  </style>
  
  